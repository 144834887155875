.roomControlModal {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);    
    border-radius: 12px;
    position:  fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: unset !important;
    z-index: 2;
 }
 
 .roomControlTitle {
    text-align: left;
 }

 .roomControlModalOverlay {
    background-color: unset !important;
    z-index: -1 !important;
    box-shadow: unset !important;
 }

 .sliderContainer {
   height: 100px;
   padding-bottom: 5px;
   justify-items: center;
 }
 
 .rcs {
   width: 724px;
 }